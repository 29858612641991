var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from "../../components/Card/Card";
import { Loader } from "../../components/Loader/loader";
import { useSelector } from "react-redux";
import { useStartupProfileView } from "@src/hooks/pagination/profileView/startupProfileView/startupProfileView";
import StartupByIdAboutBlock from "./StartupByIdAboutBlock/StartupByIdAboutBlock";
import StartupByIdStepsBlock from "./StartupByIdStepsBlock/StartupByIdStepsBlock";
export var StartupByIdChange = function (_a) {
    var _b, _c;
    var startupByIdInformation = _a.startupByIdInformation, bottomBoundaryRef = _a.bottomBoundaryRef;
    var type = useSelector(function (state) { return state.startupById.type; });
    var _d = useStartupProfileView(bottomBoundaryRef), data = _d.data, isFetching = _d.isFetching;
    return type === 4 ? (_jsx(StartupByIdAboutBlock, { startupByIdInformation: startupByIdInformation })) : type === 5 ? (_jsx(StartupByIdStepsBlock, { contacts: startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.contacts })) : (_jsx(_Fragment, { children: ((_c = (_b = data === null || data === void 0 ? void 0 : data.pages) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.count) !== 0 && (_jsxs(_Fragment, { children: [_jsx("div", { children: (data === null || data === void 0 ? void 0 : data.pages) &&
                        data.pages.map(function (page) { return (page === null || page === void 0 ? void 0 : page.results) && page.results.map(function (item, index) { return _jsx(Card, __assign({ offLikes: type === 3 ? true : false }, item, { offFollowerIcon: false }), index); }); }) }), isFetching ? _jsx(Loader, {}) : null] })) }));
};
