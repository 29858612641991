var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import style_layout from "../../styles/layout/grid.module.css";
import { useAppDispatch } from "../../redux/store";
import { Post } from "../../components/PostCardForOpenedPublication/postCard/Post";
import { getClearOpenNews, getOpenedPublicationTH } from "../../redux/reducers/openedNewsReducer/openNewsReducer";
import { useMediaQuery } from "react-responsive";
import { Loader } from "../../components/Loader/loader";
import { RightSetBlockOnMainPage } from "../../components/RightSets/RightSetForHomePage/RightSetOnMainPage";
import { getNewsTC } from "../../redux/newsReducer";
import { useSelector } from "react-redux";
import style from "./OpenStartupPublicationPage.module.css";
import { ButtonUp } from "@src/components/Button/ButtonUp/ButtonUp";
var OpenStartupPublicationPage = function () {
    var isMobile = useMediaQuery({
        query: "(max-width: 480px)",
    });
    var isPlanshet = useMediaQuery({
        query: "(max-width: 1240px)",
    });
    var id = useParams().id;
    var openedNews = useSelector(function (state) { return state.openNews.openedPublication; });
    var isLoading = useSelector(function (state) { return state.isLoading.isLoading; });
    var dispatch = useAppDispatch();
    useEffect(function () {
        dispatch(getNewsTC());
    }, []);
    useEffect(function () {
        dispatch(getOpenedPublicationTH("news", id));
    }, [id]);
    useEffect(function () {
        return function () {
            dispatch(getClearOpenNews());
        };
    }, []);
    return (_jsxs("div", __assign({ className: isPlanshet ? style_layout.col_main_content_section_vertical_mobile_with_mt80 : style_layout.col_main_content_section_horizontal }, { children: [_jsx("div", __assign({ className: isPlanshet ? style_layout.col_main_content_section_vertical_mobile : style_layout.col_middle_large }, { children: isLoading ? _jsx(Loader, {}) : _jsx(Post, { onePost: openedNews }) })), !isMobile && !isPlanshet && (_jsx("div", __assign({ className: style.stickyWrapper }, { children: _jsxs("div", __assign({ className: style_layout.col_rightview_small_for_page }, { children: [_jsx(RightSetBlockOnMainPage, {}), _jsx(ButtonUp, {})] })) })))] })));
};
export default OpenStartupPublicationPage;
