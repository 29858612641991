// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OJ4BQFBZ2549JEqnZnwN {
    min-width: 300px;
    max-width: 300px;
    max-height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
    position: sticky;
    top: 70px;
    right: 0;
}

.OJ4BQFBZ2549JEqnZnwN::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
}

.OJ4BQFBZ2549JEqnZnwN::-webkit-scrollbar-thumb {
    display: none;
}

.OJ4BQFBZ2549JEqnZnwN::-webkit-scrollbar-track {
    margin-top: 20px;
    margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/OpenStartupPublicationPage/OpenStartupPublicationPage.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,SAAS;IACT,QAAQ;AACZ;;AAEA;IACI,UAAU;IACV,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".stickyWrapper {\r\n    min-width: 300px;\r\n    max-width: 300px;\r\n    max-height: 90vh;\r\n    overflow-y: scroll;\r\n    overflow-x: hidden;\r\n    position: sticky;\r\n    top: 70px;\r\n    right: 0;\r\n}\r\n\r\n.stickyWrapper::-webkit-scrollbar {\r\n    width: 4px;\r\n    background-color: transparent;\r\n}\r\n\r\n.stickyWrapper::-webkit-scrollbar-thumb {\r\n    display: none;\r\n}\r\n\r\n.stickyWrapper::-webkit-scrollbar-track {\r\n    margin-top: 20px;\r\n    margin-bottom: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stickyWrapper": `OJ4BQFBZ2549JEqnZnwN`
};
export default ___CSS_LOADER_EXPORT___;
