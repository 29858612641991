var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { ContentTitleAdaptive } from "../../components/ContentTitleAdaptive/ContentTitleAdaptive";
import { Loader } from "../../components/Loader/loader";
import { Card } from "../../components/Card/Card";
import { ButtonUp } from "../../components/Button/ButtonUp/ButtonUp";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import style from "./Startups.module.css";
import style_layout from "../../styles/layout/grid.module.css";
import { RightSetBlockOnMainPage } from "@src/components/RightSets/RightSetForHomePage/RightSetOnMainPage";
import { useAppDispatch } from "@src/redux/store";
import { getNewsTC, getPublicationNews } from "@src/redux/newsReducer";
import { useSelector } from "react-redux";
import StartupPageSortButton from "./StartupPageSortButton";
import { useGetSortedPublicationOnStartupsPage } from "@src/hooks/pagination/pages/useGetSortedStartupsPublications/useGetSortedStartupsPublications";
import { setButtonsSortToDefault } from "@src/redux/startupsReducer";
var Startups = function () {
    var location = useLocation();
    var navigate = useNavigate();
    var bottomBoundaryRef = useRef(null);
    var dispatch = useAppDispatch();
    var isAddedOrRemovedFromAdsBlock = useSelector(function (state) { return state.adminAdsModeration.isAddedOrRemovedFromAdsBlock; });
    var filterButtonsLeftBlock = useSelector(function (state) { return state.startups.filterButtonsLeftBlock; });
    var url = useSelector(function (state) { return state.startups.leftButtonUrl; });
    var _a = useGetSortedPublicationOnStartupsPage(bottomBoundaryRef), data = _a.data, isFetching = _a.isFetching;
    var isPlanshet = useMediaQuery({
        query: "(max-width: 1240px)",
    });
    var onNavigateToCompaniesSearch = function () {
        navigate(ROUTES.companies_serch_page);
    };
    useEffect(function () {
        window.scrollTo(0, 0);
        return function () {
            dispatch(setButtonsSortToDefault());
        };
    }, []);
    useEffect(function () {
        dispatch(getNewsTC());
        dispatch(getPublicationNews());
    }, [isAddedOrRemovedFromAdsBlock]);
    return (_jsxs(_Fragment, { children: [_jsxs(Helmet, { children: [_jsx("title", { children: "Стартапы" }), _jsx("meta", { name: "description", content: "Страница стартапов", "data-rh": "true" }), _jsx("meta", { property: "og:type", content: "website" }), _jsx("link", { rel: "canonical", href: "https://itnews.pro/startups" })] }), _jsxs("div", __assign({ className: isPlanshet ? style_layout.col_main_content_section_vertical_mobile_with_mt80 : style_layout.col_main_content_section_horizontal }, { children: [_jsxs("div", __assign({ className: isPlanshet ? style_layout.col_middle_large_mobile : style_layout.col_middle_large }, { children: [_jsxs("div", __assign({ className: style.page_head }, { children: [_jsx(StartupPageSortButton, { MainSortButtons: filterButtonsLeftBlock }), _jsx("div", __assign({ className: style.slide_size_block }, { children: isPlanshet && location.pathname === "/startups/" && (_jsx("div", __assign({ className: style.titleAdaptive }, { children: _jsx(ContentTitleAdaptive, { onClickLogic: onNavigateToCompaniesSearch, title: "СТАРТАПЫ", modalName: "mobile-filter-for-companies-modal" }) }))) }))] })), (data === null || data === void 0 ? void 0 : data.pages) ? (data.pages.map(function (page) { var _a; return (_a = page === null || page === void 0 ? void 0 : page.results) === null || _a === void 0 ? void 0 : _a.map(function (item, index) { return _jsx(Card, __assign({}, item, { offFollowerIcon: false, offLikes: false, url: url === 'list/' ? "startupsProfile/" : "startups/" }), item.id); }); })) : (
                            // ниже дивка заглушка
                            _jsx("div", { style: { width: "660px" } })), _jsx("div", __assign({ className: style.bottom_observer, ref: bottomBoundaryRef }, { children: "&nbsp" })), isFetching && _jsx(Loader, {})] })), isPlanshet ? null : (_jsx("div", __assign({ className: style.stickyWrapper }, { children: _jsxs("div", __assign({ className: style_layout.col_rightview_small_for_page }, { children: [_jsx(RightSetBlockOnMainPage, {}), _jsx(ButtonUp, {})] })) })))] }))] }));
};
export default Startups;
