var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { useAppDispatch } from "../../redux/store";
import { useMediaQuery } from "react-responsive";
import { setButtonsLeftBlock } from "@src/redux/startupsReducer";
var SortBox = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    margin-bottom: ", ";\n    position: relative;\n"], ["\n    display: flex;\n    margin-bottom: ", ";\n    position: relative;\n"])), function (props) { return props.marginBottom; });
var ScrollableContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    align-items: center;\n    overflow-x: scroll;\n    overflow-y: visible;\n    &::-webkit-scrollbar {\n        display: none;\n    }\n"], ["\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    align-items: center;\n    overflow-x: scroll;\n    overflow-y: visible;\n    &::-webkit-scrollbar {\n        display: none;\n    }\n"])));
var LeftPart = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    gap: 10px;\n"], ["\n    display: flex;\n    gap: 10px;\n"])));
var MainSortButton = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 10px;\n    background: ", ";\n    color: ", ";\n    font-size: 13px;\n    letter-spacing: 0.3px;\n    line-height: 11px;\n    font-weight: 400;\n    height: 30px;\n    border-radius: 8px;\n    cursor: pointer;\n    outline: none;\n    border: ", ";\n    transition: all 0.3s;\n    min-width: ", ";\n    max-width: ", ";\n\n    &:hover,\n    &:focus {\n        background: ", ";\n\n        border: none;\n    }\n    &:first-child {\n        margin-left: ", ";\n    }\n"], ["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 10px;\n    background: ", ";\n    color: ", ";\n    font-size: 13px;\n    letter-spacing: 0.3px;\n    line-height: 11px;\n    font-weight: 400;\n    height: 30px;\n    border-radius: 8px;\n    cursor: pointer;\n    outline: none;\n    border: ", ";\n    transition: all 0.3s;\n    min-width: ", ";\n    max-width: ", ";\n\n    &:hover,\n    &:focus {\n        background: ", ";\n\n        border: none;\n    }\n    &:first-child {\n        margin-left: ", ";\n    }\n"])), function (props) { return props.bg; }, function (props) { return props.color; }, function (props) { return props.border; }, function (props) { return props.width; }, function (props) { return props.width; }, function (props) { return props.hover; }, function (props) { return props.firstButtonPadding; });
var StartupPageSortButton = function (_a) {
    var MainSortButtons = _a.MainSortButtons;
    var dispatch = useAppDispatch();
    var isMobile = useMediaQuery({
        query: "(max-width: 1240px)",
    });
    var handleChangeMainButton = function (btn) {
        dispatch(setButtonsLeftBlock(btn));
    };
    return (_jsx(SortBox, __assign({ marginBottom: isMobile ? "15px" : "7px" }, { children: _jsx(ScrollableContent, __assign({ scroll: "scroll" }, { children: _jsx(LeftPart, { children: MainSortButtons === null || MainSortButtons === void 0 ? void 0 : MainSortButtons.map(function (button) { return (_jsx(MainSortButton, { firstButtonPadding: isMobile ? "18px" : "0", width: isMobile ? "unset" : "120px", hover: button.check ? "#1482c5" : "#d4dadd", bg: button.check ? "#1482c5" : "#F2F5F9", color: button.check ? "white" : "#4f4f4f", border: "none", type: "button", onClick: function () { return handleChangeMainButton(button); }, value: button.title }, button.id)); }) }) })) })));
};
export default StartupPageSortButton;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
