var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import StartupCardFooter from '@src/components/Card/StartupCardFooter/StartupCardFooter';
import styles from './StartupByIdPageRightSet.module.css';
import { NEW_URL } from '@src/api/API';
import repost_icon from "@assets/img/repost_icon_new_icon.svg";
import Like_icon from "@assets/img/new_icon_unliked.svg";
import filled_like_icon from "@assets/img/new_icon_filled_like_.svg";
import { useState } from 'react';
import { RWebShare } from 'react-web-share';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@src/redux/store';
import { setAuthModalOpenStatus } from '@src/redux/modalReducer';
import { addOrDeleteLikeTC } from '@src/redux/likesReduser';
import LoadableImage from '@src/components/LoadableImage/LoadableCoverInCard/LoadableImage';
export var StartupByIdPageRightset = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j;
    var startupByIdInformation = _a.startupByIdInformation;
    var isAuth = useSelector(function (state) { return state.auth.isAuth; });
    var dispatch = useAppDispatch();
    var _k = useState((_c = (_b = startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.about) === null || _b === void 0 ? void 0 : _b.likes) !== null && _c !== void 0 ? _c : 0), countLikes = _k[0], setCountLikes = _k[1];
    var _l = useState((_e = (_d = startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.about) === null || _d === void 0 ? void 0 : _d.inlikes) !== null && _e !== void 0 ? _e : false), inlikes = _l[0], setInlikes = _l[1];
    var handleCountLike = function () {
        var _a;
        if (isAuth === false || !isAuth) {
            dispatch(setAuthModalOpenStatus({ isOpen: true }));
            return;
        }
        if (inlikes) {
            setCountLikes(countLikes - 1);
            setInlikes(false);
        }
        if (!inlikes) {
            setCountLikes(countLikes + 1);
            setInlikes(true);
        }
        dispatch(addOrDeleteLikeTC((_a = startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.about) === null || _a === void 0 ? void 0 : _a.id, 'startups/'));
    };
    return (_jsx("div", __assign({ className: styles.startupRightSet }, { children: (startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.about) ? (_jsxs("div", __assign({ className: styles.startupRightSet_content }, { children: [_jsxs("div", __assign({ className: styles.startupRightSet_head }, { children: [_jsx("img", { src: NEW_URL + (startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.avatar), alt: "", className: styles.startupRightSet_avatar }), _jsx("p", __assign({ className: styles.startupRightSet_title }, { children: startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.name }))] })), _jsx("div", __assign({ className: styles.startupRightSet_cover }, { children: _jsx(LoadableImage, { src: "".concat(NEW_URL).concat((_f = startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.about) === null || _f === void 0 ? void 0 : _f.cover) }) })), _jsx(StartupCardFooter, { stage_complete: (_g = startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.about) === null || _g === void 0 ? void 0 : _g.stage_complete, stage_count: (_h = startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.about) === null || _h === void 0 ? void 0 : _h.stage_count, budget: (_j = startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.about) === null || _j === void 0 ? void 0 : _j.budget }), _jsxs("div", __assign({ className: styles.startupRightSet_footer }, { children: [_jsx(RWebShare, __assign({ closeText: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C", data: {
                                url: "http://itnews.pro/startupProfile/".concat(startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.id_full, "/about"),
                                title: startupByIdInformation === null || startupByIdInformation === void 0 ? void 0 : startupByIdInformation.name,
                            }, sites: ["vk", "mail", "okru", "copy", "telegram", "twitter", "facebook"] }, { children: _jsx("button", __assign({ style: {
                                    backgroundColor: "transparent",
                                    border: "none",
                                    padding: "0",
                                    height: "1.5rem",
                                    width: "1.5rem",
                                    minHeight: "20px",
                                    minWidth: "28px",
                                } }, { children: _jsx("img", { src: repost_icon, style: { cursor: "pointer" } }) })) })), _jsx("div", __assign({ onClick: handleCountLike }, { children: inlikes ? (_jsxs("div", __assign({ className: styles.like }, { children: [_jsx("span", __assign({ className: styles.spanInlike }, { children: countLikes })), _jsx("img", { src: filled_like_icon })] }))) : (_jsxs("div", __assign({ className: styles.like }, { children: [_jsx("span", __assign({ className: styles.spanUnlike }, { children: countLikes })), _jsx("img", { src: Like_icon })] }))) }))] }))] }))) : (_jsx("div", {})) })));
};
